import request from '@/utils/request'

// 分页查询
export function queryPage(data) {
  return request({
    url: '/MA_siteorder/queryPage',
    method: 'post',
    params: data
  })
}

//预约审核 	/MA_siteorder/audit
export function auditOrder(data) {
  return request({
    url: '/MA_siteorder/audit',
    method: 'post',
    params: data
  })
}